import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"
import posed, { PoseGroup } from "react-pose"
import { FaLinkedinIn, FaFacebookF, FaAmilia } from "react-icons/fa"
import "./style.scss"
import headerStyle from "./style.module.scss"
import { linkPath } from "../../util/path"

const transition = {
  duration: 450,
}

const MenuContainer = posed.div({
  open: {
    applyAtStart: { display: "block" },
    opacity: 1,
    transition,
    delayChildren: 200,
    staggerChildren: 50,
  },
  closed: {
    applyAtEnd: { display: "none" },
    opacity: 0,
    transition,
  },
})

const Main = posed.div({
  enter: {
    height: 60,
    transition,
  },
})

const MenuItem = posed.div({
  open: {
    x: 0,
    opacity: 1,
  },
  closed: {
    x: 100,
    opacity: 0,
  },
})
const Icon = posed.div({
  open: {
    x: 20,
    opacity: 1,
  },
  closed: {
    x: 0,
    opacity: 0,
  },
})

const Bar1 = posed.div({
  closed: {
    rotate: 0,
    x: "0",
    y: "0",
    transition,
  },
  open: {
    rotate: "45deg",
    x: "4px",
    y: "-1px",
    transition,
  },
})

const Bar2 = posed.div({
  closed: {
    opacity: 1,
    transition,
  },
  open: {
    tranform: "none",
    opacity: 0,
  },
})

const Bar3 = posed.div({
  closed: {
    rotate: 0,
    x: "0",
    y: "0",
    transition,
  },
  open: {
    rotate: "-45deg",
    x: "2px",
    y: "3px",
    transition,
  },
})

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      mobileMenuOpened: false,
      isVisible: false,
      className: `header ${headerStyle.showNavBar}`,
      scrollingUp: true,
      mobileMenuVisible: false,
    }
    this.getIcon = this.getIcon.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: true,
        top: true,
      })
    }, 1000)
    document.addEventListener("mousedown", this.handleClick)
    window.addEventListener("scroll", this.handleScroll, true)
    window.addEventListener("resize", this.handleWindowSize, true)
    this.setState({ mobileMenuVisible: window.innerWidth <= 992 })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true)
    window.removeEventListener("resize", this.handleWindowSize, true)
  }

  handleWindowSize = () => {
    if (window.innerWidth <= 992 && !this.state.mobileMenuVisible) {
      this.setState({
        mobileMenuVisible: true,
        className: `header ${headerStyle.isFixed}`,
      })
    }
    if (window.innerWidth > 992 && this.state.mobileMenuVisible) {
      this.setState({
        mobileMenuVisible: false,
      })
    }
  }

  handleScroll = () => {
    const scrollAt = window.scrollY
    let className = this.state.className

    if (!this.state.mobileMenuVisible) {
      if (this.state.scrollingUp) {
        className = `header ${headerStyle.isFixed} ${headerStyle.showNavBar}`
      } else {
        className = `header ${headerStyle.isFixed}`
        className +=
          window.scrollY > 40 && !this.state.scrollingUp
            ? ` ${headerStyle.hideNavBar}`
            : ""
      }

      this.setState({
        scrollAt,
        className,
        scrollingUp: this.state.scrollAt > window.scrollY,
      })
    }
  }

  handleClick(event) {
    if (
      window.innerWidth <= 992 &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.setState({
        mobileMenuOpened: false,
      })
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node
  }

  render() {
    const state = this.state
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
            contentfulV4MenuLetsTalk {
              menus {
                name
                url
                externalUrl
              }
              socialProfiles {
                name
                url
                icon
              }
              grioEmail
              grioOffices {
                address1
                address2
                name
              }
              grioPhoneNumber
            }
          }
        `}
        render={data => (
          <PoseGroup>
            {state.isVisible && (
              <div
                ref={ref => this.setWrapperRef(ref)}
                key="mainHeader"
                className={this.state.className}
              >
                <div className="headerInner">
                  <div className="mobilePaddingLeft">
                    <Link className="links" to={"/"} alt="Home">
                      <img className="logo" alt="logo" src={Logo} />
                    </Link>
                  </div>
                  {data.contentfulV4MenuLetsTalk.menus.map(currentMenu => (
                    <div className="linksWrappers" key={currentMenu.name}>
                      {currentMenu.externalUrl ? (
                        <Link className="links" to={"/"}>
                          {currentMenu.name}
                        </Link>
                      ) : (
                        <a
                          className={
                            currentMenu.name === "Let's Talk"
                              ? "letsTalkButton"
                              : "links"
                          }
                          href={`${linkPath}${currentMenu.url}`}
                        >
                          {currentMenu.name}
                        </a>
                      )}
                    </div>
                  ))}
                  <div
                    className="mobileToggle"
                    onClick={() => {
                      this.setState({
                        mobileMenuOpened: !state.mobileMenuOpened,
                      })
                    }}
                  >
                    <span className="menuTitle">
                      {state.mobileMenuOpened ? "" : "Menu"}
                    </span>
                    <div className="hamburgerMenu">
                      <Bar1
                        className="bar"
                        key="b1"
                        pose={state.mobileMenuOpened ? "open" : "closed"}
                      />
                      <Bar2
                        className="bar"
                        key="b2"
                        pose={state.mobileMenuOpened ? "open" : "closed"}
                      />
                      <Bar3
                        className="bar"
                        key="b3"
                        pose={state.mobileMenuOpened ? "open" : "closed"}
                      />
                    </div>
                  </div>
                  <MenuContainer
                    className="menuMobile"
                    pose={state.mobileMenuOpened ? "open" : "closed"}
                  >
                    {data.contentfulV4MenuLetsTalk.menus.map(currentMenu => (
                      <MenuItem
                        onClick={() => {
                          this.setState({
                            mobileMenuOpened: !state.mobileMenuOpened,
                          })
                        }}
                        className="linksWrapperMob"
                        key={currentMenu.name}
                      >
                        {currentMenu.externalUrl ? (
                          <Link className="links" to={"/"}>
                            {currentMenu.name}
                          </Link>
                        ) : (
                          <a
                            className="links"
                            href={`${linkPath}${currentMenu.url}`}
                          >
                            {currentMenu.name}
                          </a>
                        )}
                      </MenuItem>
                    ))}
                    <div className="offices">
                      {data.contentfulV4MenuLetsTalk.grioOffices.map(
                        current => (
                          <React.Fragment key={current.name}>
                            <p>
                              <b>{current.name}</b>
                            </p>
                            <p>{current.address1}</p>
                            <p>{current.address2}</p>
                          </React.Fragment>
                        )
                      )}
                      <p>
                        <a
                          href={`tel:${data.contentfulV4MenuLetsTalk.grioPhoneNumber.replace(
                            /\./g,
                            ""
                          )}`}
                        >
                          {data.contentfulV4MenuLetsTalk.grioPhoneNumber}
                        </a>
                      </p>
                      <p>
                        <a
                          href={`mailto:${data.contentfulV4MenuLetsTalk.grioEmail}`}
                        >
                          {data.contentfulV4MenuLetsTalk.grioEmail}
                        </a>
                      </p>
                    </div>
                    <Icon className="socialNetworks">
                      {data.contentfulV4MenuLetsTalk.socialProfiles.map(
                        current => (
                          <a
                            key={current.name}
                            href={current.url}
                            alt={current.name}
                          >
                            {this.getIcon(current.icon)}
                          </a>
                        )
                      )}
                    </Icon>
                  </MenuContainer>
                </div>
              </div>
            )}
          </PoseGroup>
        )}
      />
    )
  }
  getIcon(iconFromContentful = "", url = "") {
    switch (iconFromContentful) {
      case "fb":
        return <FaFacebookF />
      case "linkedin":
        return <FaLinkedinIn />
      default:
        return <FaAmilia />
    }
  }
}

export default Header
