module.exports = [{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":true,"dataLayerName":"dataLayer"},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[null],"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://qa.blog.grio.com"},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"grio-site-new","short_name":"grio","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/ms-icon-310x310.png"},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Ubuntu","alwyn-new-web"],"urls":["https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap","https://use.typekit.net/loh5dnw.css"]}},
    },{
      plugin: require('/home/circleci/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
